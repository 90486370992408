const RolesDescriptions = {
  admin: 'Unrestricted access to the account, its data, and its settings.',
  member: 'Members can only view and act on the data account.',
};

const MembersRoles = {
  admin: 'Administrator',
  member: 'Member',
  superGuuru: 'Super Guuru',
};

const GlobalRoles = {
  salesManager: 'Sales Manager',
};

const NoPartnerRoles = {
  tagger: 'Tagger',
};

const AllRoles = {
  financeManager: 'Finance Manager',
  communityManager: 'Community Manager',
  productManager: 'Product Manager',
  engineer: 'Engineer',
  salesGlobalAll: 'Global Sales',
  superAdmin: 'Super Admin',
};

const Permissions = {
  permissionsManager: 'Permissions Manager',
};

const BaseRoles = {
  guuru: 'Guuru',
  agent: 'Agent',
  token: 'token',
  anonymous: 'anonymous',
};

const PossibleRoles = {
  ...BaseRoles,
  ...MembersRoles,
  ...GlobalRoles,
  ...AllRoles,
  ...Permissions,
  ...NoPartnerRoles,
};

module.exports.membersRoles = MembersRoles;
module.exports.possibleRoles = PossibleRoles;
module.exports.allRoles = AllRoles;
module.exports.noPartnerRoles = NoPartnerRoles;
module.exports.globalRoles = GlobalRoles;
module.exports.permissionsRoles = Permissions;
module.exports.baseRoles = BaseRoles;
module.exports.rolesDescriptions = RolesDescriptions;
