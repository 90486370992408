import React, { useContext } from 'react';
import {
  Outlet,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import styled from 'styled-components';
import { useChatSettings } from '@guuru/chat-web';
import { Flex } from 'antd';
import BottomMenu from '../BottomMenu';
import GlobalStyle from '../styles/GlobalStyle';
import ExternalNavigationContext from '../context/ExternalNavigationContext';

const excludedPaths = ['/form', '/transcript'];

const Layout = styled(Flex)`
  height: 100vh;
`;

const BaseLayout = function () {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const waitReady = useContext(ExternalNavigationContext);

  const {
    data: {
      chatSettings: {
        chatWidgetFont,
      } = {},
    } = {},
  } = useChatSettings();

  if (waitReady) return null;

  const showBottomMenuInPath = (
    !excludedPaths.some((path) => pathname.includes(path))
  );
  const spaces = searchParams.get('spaces')?.split(',') ?? [];
  const hasMultipleSpaces = spaces.length > 1;

  return (
    <Layout vertical="false">
      <GlobalStyle font={chatWidgetFont} transparent={false} />
      <main>
        <Outlet />
      </main>
      {showBottomMenuInPath && hasMultipleSpaces && (
        <BottomMenu spaces={spaces} />
      )}
    </Layout>
  );
};

export default BaseLayout;
