import styled from 'styled-components';
import { Col } from 'antd';

const FeedbackContainer = styled(Col)`
  padding-top: 24px;

  @media only screen and (min-width: 1025px) {
    align-items: center;

    .ant-carousel {
      max-width: 1020px;
    }
  }
`;

export default FeedbackContainer;
