const expandEvent = () => {
  global.window.parent.postMessage(
    { id: 'chat:formExpand' },
    '*',
  );
};

const shrinkEvent = () => {
  global.window.parent.postMessage(
    { id: 'chat:formShrink' },
    '*',
  );
};

const delegateToClientFormEvent = (question) => {
  global.window.parent.postMessage(
    {
      id: 'chat:delegateToClientForm',
      data: { question },
    },
    '*',
  );
};

const heightUpdatedEvent = (height) => {
  global.window.parent.postMessage(
    { id: 'chat:heightUpdated', data: { height } },
    '*',
  );
};

const newMessageNotificationEvent = (unreadMessagesCount) => {
  global.window.parent.postMessage(
    { id: 'chat:addNewMessageNotification', data: { unreadMessagesCount } },
    '*',
  );
};

const chatReadyEvent = () => {
  global.window.parent.postMessage(
    { id: 'chat:ready' },
    '*',
  );
};

export {
  expandEvent,
  shrinkEvent,
  delegateToClientFormEvent,
  heightUpdatedEvent,
  newMessageNotificationEvent,
  chatReadyEvent,
};
