import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GET_STATS from './queries/getStats';
import Statistic from '../Statistic';

const TotalMembers = function () {
  const { partnerId } = useParams();
  const { t } = useTranslation();

  const {
    data: {
      community: {
        stats: {
          totalMembers,
        } = {},
      } = {},
    } = {},
    loading,
  } = useQuery(GET_STATS, {
    variables: { partnerId },
  });

  return (
    <Statistic title={t('chat:::members')} value={totalMembers} loading={loading} />
  );
};

export default TotalMembers;
