import styled from 'styled-components';
import { Row } from 'antd';

export default styled(Row)`
  align-items: center;
  max-height: 64px;
  background-color: ${({ theme }) => theme.primaryColor};
  justify-content: space-between;
  padding: 8px;

  .ant-typography {
    margin: 0;
    padding: 8px;
  }

  a, a:hover, a:visited, a:link {
    color: ${({ theme }) => theme.onSecondaryColor};
    text-decoration: none;
  }
`;
