import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import formatRelativeTime from '../../utilities/time/formatRelativeTime';

const { Text } = Typography;

const RelativeTime = function ({ date, locale = 'en' }) {
  return <Text type="secondary">{formatRelativeTime(date, locale)}</Text>;
};

RelativeTime.propTypes = {
  locale: PropTypes.string,
  date: PropTypes.number.isRequired,
};

export default RelativeTime;
