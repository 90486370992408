const units = [
  { name: 'year', amount: 1000 * 60 * 60 * 24 * 365 },
  { name: 'month', amount: 1000 * 60 * 60 * 24 * 30 },
  { name: 'day', amount: 1000 * 60 * 60 * 24 },
];

const formatRelativeTime = (date, locale) => {
  const joinedAtDate = new Date(date);
  const nowDate = new Date();

  const dateUnit = units.find(({ amount }) => {
    const value = Math.floor((nowDate - joinedAtDate) / amount);
    return value > 0;
  });

  const format = { unit: 'day', value: 0 };

  if (dateUnit) {
    const value = Math.floor((nowDate - joinedAtDate) / dateUnit.amount);
    format.unit = dateUnit.name;
    format.value = -value;
  }

  const { unit, value } = format;
  const relativeTime = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });

  return relativeTime.format(value, unit);
};

export default formatRelativeTime;
