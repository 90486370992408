import React, { Suspense } from 'react';
import ThemeProvider from '@guuru/theme-web';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoadingView from '@guuru/react-loading';
import useTheme from './hooks/useTheme';
import useSetLanguage from './hooks/useSetLanguage';
import useInitChatSettings from './hooks/useInitChatSettings';
import useToken from './hooks/useToken';
import useInitParams from './hooks/useInitParams';
import useInitGoogleAnalytics from './hooks/useInitGoogleAnalytics';
import Form from '../modules/chat/views/form';
import Transcript from '../modules/chat/views/Transcript';
import Chat from '../modules/chat/views/chat';
import BaseLayout from './BaseLayout';
import ChatLayout from '../modules/chat/containers/ChatLayout';
import CommunityLayout from '../modules/community/containers/CommunityLayout';
import InvitationLayout from '../modules/community/containers/InvitationLayout';
import Expert from '../modules/community/views/Expert';
import Home from '../modules/community/views/Home';
import AllExperts from '../modules/community/views/AllExperts';
import RouteHistory from './RouteHistory';
import Invitation from '../modules/community/views/Invitation';
import { ExternalNavigationProvider } from './context/ExternalNavigationContext';

const MainView = function () {
  const { partnerIdParam } = useInitParams();
  const { loading, partnerId } = useInitChatSettings(partnerIdParam);
  const { tokenInvalidMessage } = useToken(partnerId);
  const { loading: loadingTheme, ...theme } = useTheme(partnerId);
  useSetLanguage(partnerId);
  useInitGoogleAnalytics(partnerId);

  if (loading || loadingTheme || !partnerId || tokenInvalidMessage) {
    return (
      <LoadingView motion={loading} loadingMessage={tokenInvalidMessage} />
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Suspense fallback={<LoadingView />}>
          <RouteHistory>
            <ExternalNavigationProvider>
              <Routes>
                <Route path="/:partnerId" element={<BaseLayout />}>
                  <Route element={<ChatLayout />}>
                    <Route index element={<Chat />} />
                    <Route path=":locale" element={<Chat />} />
                    <Route path="chats">
                      <Route index element={<Chat />} />
                      <Route path=":chatId">
                        <Route index element={<Chat />} />
                        <Route path=":chatControlToken" element={<Chat />} />
                      </Route>
                    </Route>
                    <Route path="form">
                      <Route index element={<Form />} />
                      <Route path=":chatId">
                        <Route index element={<Form />} />
                        <Route path=":chatControlToken" element={<Form />} />
                      </Route>
                    </Route>
                    <Route path="transcripts/:chatId/:chatControlToken" element={<Transcript />} />
                  </Route>
                  <Route element={<CommunityLayout />}>
                    <Route path="home" element={<Home />} />
                    <Route path="guurus" element={<AllExperts />} />
                    <Route path="guurus/:expertId" element={<Expert />} />
                  </Route>
                </Route>
                <Route path="/:partnerId/invitation" element={<InvitationLayout />}>
                  <Route index element={<Invitation />} />
                </Route>
              </Routes>
            </ExternalNavigationProvider>
          </RouteHistory>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default MainView;
