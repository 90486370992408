import React from 'react';
import { List } from 'antd';
import { scaleRating } from '@guuru/rating-common';
import { Rating } from '@guuru/react-rating';
import { HighlightCard } from '@guuru/react-card';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { LoadMore } from '@guuru/react-pagination';
import { useTranslation } from 'react-i18next';
import EmptyList from './EmptyList';
import GET_EXPERTS from './queries/getExperts';
import RelativeTime from '../../common/RelativeTime';

const ExpertsList = function ({ url = '', first = 20 }) {
  const { partnerId } = useParams();
  const { t, i18n: { language } } = useTranslation();

  const {
    data: {
      community: {
        experts: {
          edges: experts = [],
          pageInfo,
        } = {},
      } = {},
    } = {},
    loading,
    fetchMore,
  } = useQuery(GET_EXPERTS, {
    variables: {
      partnerId,
      first,
      loadMore: first > 10,
    },
  });

  const { search } = useLocation();
  const isReady = experts.length >= 2;

  return (
    <List
      dataSource={isReady ? experts : []}
      loading={loading}
      locale={{ emptyText: <EmptyList /> }}
      grid={{
        gutter: [24, 8],
        xs: 1,
        sm: 2,
        md: 3,
        lg: 4,
        xl: 4,
        xxl: 4,
      }}
      loadMore={(
        <LoadMore
          flex="none"
          data={{ pageInfo }}
          fetchMore={fetchMore}
          loading={loading}
          size="large"
        />
      )}
      renderItem={({ node: { externalId: key, ...expert } }) => (
        <List.Item>
          <HighlightCard
            key={key}
            title={expert.name}
            avatar={expert.photoUrl}
            description={expert.bio}
            url={`${url}${key}${search}`}
            footer={<>{t('pages:::memberSince')} <RelativeTime date={expert.joinedAt} locale={language} /></>}
            extraTitle={(
              <Rating rating={scaleRating(expert.rating)} hoverable={false} />
            )}
          />
        </List.Item>
      )}
    />
  );
};

ExpertsList.propTypes = {
  first: PropTypes.number,
  url: PropTypes.string,
};

export default ExpertsList;
