import React from 'react';
import { Typography } from 'antd';
import { useCommunitySettings } from '@guuru/community-web';
import { usePageTitle } from '@guuru/react-hooks';
import { useTranslation } from 'react-i18next';
import Stats from '../../components/Stats';
import Feedback from '../../components/Feedback';
import HomeExperts from '../../components/Experts/HomeExperts';

const { Title } = Typography;

const Home = function () {
  const { partnerName } = useCommunitySettings();
  usePageTitle(`Community - ${partnerName}`);
  const { t } = useTranslation();
  return (
    <>
      <Title level={2} style={{ marginBottom: 0, paddingTop: 24, textAlign: 'center' }}>
        {t('chat:::communityHeadline')}
      </Title>
      <Stats />
      <HomeExperts />
      <Feedback />
    </>
  );
};

export default Home;
