import React from 'react';
import {
  Col, Divider, Flex, Typography,
} from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CommentsList from './CommentsList';

const { Title } = Typography;

const Comments = function ({ expert }) {
  const { t } = useTranslation();

  const { bestFeedbackComments } = expert;

  return !!bestFeedbackComments?.length && (
    <Flex wrap justify="center" gap="large">
      <Divider />
      <Col span={24}>
        <Title level={4}>{t('chat:::communityGuuruFeedbacks')}</Title>
      </Col>
      <Col span={24}>
        <CommentsList dataSource={bestFeedbackComments} />
      </Col>
    </Flex>
  );
};

Comments.propTypes = {
  expert: PropTypes.shape({
    bestFeedbackComments: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default Comments;
