import React from 'react';
import {
  Col, ConfigProvider, Row, Statistic,
} from 'antd';
import PropTypes from 'prop-types';
import { CommentOutlined, LoginOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import formatRelativeTime from '../../utilities/time/formatRelativeTime';

const Details = function ({ expert }) {
  const { joinedAt, conversationLevel } = expert;
  const { t, i18n: { language } } = useTranslation();

  return (
    <ConfigProvider
      theme={{
        components: {
          Statistic: {
            titleFontSize: 16,
            contentFontSize: 15,
          },
        },
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Statistic
            title={t('pages:::memberSince')}
            value={formatRelativeTime(joinedAt, language)}
            prefix={<LoginOutlined />}
            valueStyle={{ textTransform: 'capitalize' }}
          />
        </Col>
        {!!conversationLevel && (
          <Col span={12}>
            <Statistic
              title={t('chat:::customersHelped')}
              value={`${conversationLevel}+`}
              prefix={<CommentOutlined />}
            />
          </Col>
        )}
      </Row>
    </ConfigProvider>
  );
};

Details.propTypes = {
  expert: PropTypes.shape({
    joinedAt: PropTypes.number,
    conversationLevel: PropTypes.number,
  }).isRequired,
};

export default Details;
