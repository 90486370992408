import styled from 'styled-components';

const InfiniteScrollContainer = styled.div`
  border-radius: ${(props) => props.theme.smallRadius};
  overflow: auto;

  .ant-list-split .ant-list-header {
    border-bottom: inherit;
    text-align: center;
  }
`;

export default InfiniteScrollContainer;
