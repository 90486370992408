import React, {
  createContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { chatReadyEvent } from '@guuru/chat-events-web';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ExternalNavigationContext = createContext();

export const ExternalNavigationProvider = function ({ children }) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const [waitingSignal, setWaitingSignal] = useState(params.get('waitSignal'));
  const replace = useRef(false);

  useEffect(() => {
    chatReadyEvent();
  }, []);

  useEffect(() => {
    const handleWidgetMessage = (event) => {
      const { type, path, options } = event.data;

      if (type === 'navigate' && path) {
        setWaitingSignal(false);
        navigate(path, {
          ...options,
          state: { hideBack: true, external: true },
          replace: replace.current,
        });
        replace.current = true;
      }

      if (replace.current && type === 'widgetClosed') {
        navigate(-1);
        replace.current = false;
      }
    };

    const handleBackAndForward = ({ state }) => {
      const { usr } = state || {};

      replace.current = !!usr?.external;
    };

    window.addEventListener('message', handleWidgetMessage);
    window.addEventListener('popstate', handleBackAndForward);

    return () => {
      window.removeEventListener('message', handleWidgetMessage);
      window.removeEventListener('popstate', handleBackAndForward);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ExternalNavigationContext.Provider value={waitingSignal}>
      {children}
    </ExternalNavigationContext.Provider>
  );
};

ExternalNavigationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ExternalNavigationContext;
