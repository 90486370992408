import React from 'react';
import events from '@guuru/events-web';
import { Link, useLocation } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import useSessionHistory
  from '../../../../../navigation/hooks/useSessionHistory';

const { Title } = Typography;

const GoBackLink = function () {
  const { state } = useLocation();
  const { history } = useSessionHistory();
  const { hideBack } = state || {};
  const canGoBack = !hideBack && history.length > 1;

  return canGoBack && (
    <Title level={5}>
      <Link to={-1} onClick={events.communityBackButtonClicked}>
        <LeftOutlined />
      </Link>
    </Title>
  );
};

export default GoBackLink;
